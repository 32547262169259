import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import history from './../../history';
import View from 'components/View/View';
import Footer from 'components/View/Footer/Footer';
import StyledButton from 'components/UI/StyledButton/StyledButton';
import StyledButtonWrap from 'components/UI/StyledButton/StyledButtonWrap/StyledButtonWrap';
import SelectList from 'components/UI/SelectList/SelectList';
import Navbar from 'components/View/Navbar/Navbar';
import NavbarTitle from 'components/View/Navbar/NavbarTitle/NavbarTitle';
import NavbarLeft from 'components/View/Navbar/NavbarLeft/NavbarLeft';
import NavbarRight from 'components/View/Navbar/NavbarRight/NavbarRight';
import NavbarButton from 'components/View/Navbar/NavbarButton/NavbarButton';
import Page from 'components/View/Page/Page';
import PageContent from 'components/View/PageContent/PageContent';
import DateInput from 'components/inputs/date';
import AdvancedList from 'components/advanced-list';
import { saveWorkout } from 'store/actions/logbook';
import styles from './CreateWorkout.module.css';
import constants from './../../constants';
import {delay, getWorkoutTypeFromDate, openWorkout} from '../../helpers/common';
import ConfirmModal from 'components/confirm';
import DisconnectedModal from "../../components/Modal/disconnected";
import {track} from "../../mixpanel";

class CreateWorkout extends Component {
  state = {
    isDisconnectedShown: false,
    isShortList: true,
    selected: [],
    workoutDate: null,
    showConfirm: false,
    confirmMessage: '',
    confirmPrompt: '',
    existingWorkout: {},
    submitDisabled: true,
  };

  constructor(props) {
    super(props);

    this.state.isSetup = this.props.location.search.includes(`setup=1`) > 0;

    if (this.state.isSetup) {
      this.state.workoutDate = moment();
    }
  }

  buildConfirmMessage = date => {
    return (
      <div>
        <div>A workout already exists for</div>
        <div>{moment(date).format('ddd, MMM D, YYYY')}.</div>
      </div>
    );
  };

  closeHandler = () => history.push('/logbook');

  retryContinueHandler = async () => {
    track("Retry create workout clicked")
    this.setState({isDisconnectedShown: false});
    await this.continueHandler();
  };

  continueHandler = async () => {
    this.setState({submitDisabled: true});

    if (!this.props.isConnected) {
      await delay(3000);

      if (!this.props.isConnected) {
        this.setState({submitDisabled: false, isDisconnectedShown: true});

        return;
      }
    }

    const date = moment(this.state.workoutDate, 'YYYY-MM-DD').format();
    const skip = this.checkDate(date);
    if (skip) {
      this.setState({submitDisabled: false});

      return;
    }

    const { config } = this.props;
    const exercises = (this.state.selected || [])
      .map(id => ({ ...config[id] }))
      .reduce((acc, exercise) => {
        if (isEmpty(exercise.exerciseSets)) {
          return { ...acc, [exercise.id]: { ...exercise, exerciseSets: {
            [uuid()]: { pos: 1, sets: 1, reps: 5, weight: 45 }
          } } };
        }
        return { ...acc, [exercise.id]: exercise };
      }, {});

    const isCompleated = false;
    const workoutId = await this.props.createWorkout({
      exercises,
      date,
      timestamp: moment.utc(this.state.workoutDate, 'YYYY-MM-DD').unix(),
    });

    this.setState({submitDisabled: false});
    openWorkout(workoutId, date, isCompleated, true);
  };

  checkDate = date => {
    const existingWorkout = this.getWorkoutIfExistsForDate(date);

    if (!existingWorkout) {
      return false;
    }

    this.setState({
      confirmMessage: this.buildConfirmMessage(existingWorkout.date),
      confirmPrompt: 'Would you like to view that workout instead?',
      existingWorkout,
      showConfirm: true,
    });

    return true;
  };

  onDateChange = workoutDate => {
    this.setState({ workoutDate, submitDisabled: this.state.selected.length === 0 || !workoutDate });
  };

  getWorkoutIfExistsForDate = date => {
    let workout;

    Object.keys(this.props.workouts).forEach((key) => {
      const workoutDate = this.props.workouts[key].date;
      if (date && workoutDate && moment(date).local().isSame(moment(workoutDate).local(), 'd')) {
        workout = { id: key, ...this.props.workouts[key] };
      }
    });
    return workout;
  };

  clickHandler = exerciseId => {
    let selected = [...this.state.selected];
    if (selected.indexOf(exerciseId) !== -1) {
      selected = selected.filter(ex => ex !== exerciseId);
    } else {
      selected = selected.concat(exerciseId);
    }
    this.setState({ selected, submitDisabled: selected.length === 0 || !this.state.workoutDate });
  };

  showAdvancedListClicked = () => this.setState({ isShortList: false });

  renderWorkoutDateTitle = () => {
    const workoutType = getWorkoutTypeFromDate(this.state.workoutDate);

    switch (workoutType) {
      case constants.WORKOUT_TYPES.Missing:
        return (
          <div className={styles.subtitle}>
            What was the date of this workout?
          </div>
        );
      case constants.WORKOUT_TYPES.Next:
        return (
          <div className={styles.subtitle}>What date is your next workout?</div>
        );
      default:
        return <div className={styles.title}>Select a workout date</div>;
    }
  };

  renderWorkoutDate = () => {
    if (this.state.isSetup) {
      return null;
    }
    return (
      <div>
        {this.renderWorkoutDateTitle()}
        <div>
          <DateInput
            value={this.state.workoutDate}
            onChange={this.onDateChange}
          />
        </div>
      </div>
    );
  };

  renderTitle = () => {
    const workoutType = getWorkoutTypeFromDate(this.state.workoutDate);
    let title;
    switch (workoutType) {
      case constants.WORKOUT_TYPES.Today:
        title = 'New Workout';
        break;
      case constants.WORKOUT_TYPES.Next:
        title = 'Next Workout';
        break;
      default:
        title = 'Add Workout';
    }
    return <NavbarTitle title={title} />;
  };

  renderNavBar = () => {
    if (!this.state.isSetup) {
      return (
        <Navbar>
          <NavbarLeft>
            <NavbarButton clicked={this.closeHandler}>cancel</NavbarButton>
          </NavbarLeft>
          {this.renderTitle()}
          <NavbarRight empty />
        </Navbar>
      );
    }
  };

  renderBottomButton = submitDisabled => {
    const caption = 'Continue';
    if (submitDisabled) {
      return (
        <StyledButton disabledWhite={submitDisabled}>
          <StyledButtonWrap caption={caption} />
        </StyledButton>
      );
    }
    return (
      <StyledButton
        clicked={this.continueHandler}
        disabledWhite={submitDisabled}
      >
        <StyledButtonWrap caption={caption} />
      </StyledButton>
    );
  };

  renderSelectLifts = list => {
    if (moment(this.state.workoutDate).isValid())
      return (
        <div>
          <div className={styles.title}>{this.renderSelectLiftsTitle()}</div>
          <SelectList list={list} clicked={this.clickHandler} />
          {this.state.isShortList && (
            <AdvancedList onClick={this.showAdvancedListClicked} />
          )}
        </div>
      );
  };

  renderSelectLiftsTitle = () => {
    const workoutType = getWorkoutTypeFromDate(this.state.workoutDate);

    if (workoutType === constants.WORKOUT_TYPES.Today) {
      return `Select today's lifts.`;
    }
    return `Select your lifts.`;
  };

  renderPageContent = list => {
    const withNavbar = !this.state.isSetup;
    return (
      <PageContent withNavbar={withNavbar} whiteBg>
        {this.renderWorkoutDate()}
        {this.renderSelectLifts(list)}
      </PageContent>
    );
  };

  confirmOkClick = () => {
    const { date, id: workoutId, isCompleated } = this.state.existingWorkout;

    openWorkout(workoutId, date, isCompleated);
  };

  confirmCancelClick = () => this.setState({ showConfirm: false });

  render() {
    let list = Object.keys(this.props.config)
      .map(exerciseId => {
        const exercise = this.props.config[exerciseId];

        return {
          id: exercise.id,
          title: exercise.title,
          selected: this.state.selected.indexOf(exercise.id) !== -1,
          _pos: exercise.pos,
          _mandatory: exercise.mandatory
        };
      })
      .sort((a, b) => a._pos - b._pos);

    if (this.state.isShortList) {
      list = list.filter(exercise => exercise._mandatory);
    }

    return (
      <View>
        {this.renderNavBar()}
        <Page>
          {this.renderPageContent(list)}
          <Footer>{this.renderBottomButton(this.state.submitDisabled)}</Footer>
        </Page>
        <ConfirmModal
          isOpen={this.state.showConfirm}
          confirmMessage={this.state.confirmMessage}
          confirmPrompt={this.state.confirmPrompt}
          okClicked={this.confirmOkClick}
          cancelClicked={this.confirmCancelClick}
        />
        <DisconnectedModal isOpen={this.state.isDisconnectedShown} clicked={this.retryContinueHandler} />
      </View>
    );
  }
}

const mapStateToProps = reduxState => ({
  config: reduxState.logbook.config,
  isConnected: reduxState.user.isConnected,
  workouts: reduxState.logbook.workouts,
});

const mapDispatchToProps = dispatch => ({
  createWorkout: (workout) => dispatch(saveWorkout(workout)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWorkout);
