import auth0 from 'auth0-js';
import * as Sentry from "@sentry/react";

const auth0Config = {
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
}

class Auth0Client {
  constructor() {
    this.client = new auth0.WebAuth({
      ...auth0Config,
      responseType: 'token id_token',
    });
  }

  sendEmailWithCode = async (email) => {
    return new Promise((resolve, reject) => {
      this.client.passwordlessStart({
        connection: 'email',
        send: 'code',
        email,
      }, function (err, res) {
        if (err) {
          console.log('err', err);

          return reject(err);
        }

        return resolve(res);
      });
    })
  }

  verifyCode = async (email, verificationCode) => {
    return new Promise((resolve, reject) => {
      this.client.passwordlessLogin({
        connection: 'email',
        email,
        verificationCode,
      }, function (err, res) {
        if (err) {
          console.log('err', err);

          return reject(err);
        }

        return resolve(res);
      });
    })
  }

  getIdToken = () => this._idToken;

  handleCallback = () => {
    return new Promise((resolve, reject) => {
      this.client.parseHash(async (err, authResult) => {
        window.location.hash = '';
        if (err) return reject(err);

        if (!authResult || !authResult.idToken) {
          // not an authentication request
          return resolve(false);
        }

        this._idToken = authResult.idToken;
        this._profile = authResult.idTokenPayload;

        Sentry.setUser({
          id: this._profile.sub,
          email: this._profile.email,
          ip_address: '{{auto}}'
        });

        return resolve(true);
      });
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Auth0Client();
