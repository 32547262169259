import React from 'react';

import classes from './Button.module.css';

export default class Button extends React.Component {
  render () {
    let klasses = [classes.Button, classes[this.props.btnType]];
    if (this.props.disabled) {
      klasses.push(classes.Disabled)
    }

    return (
      <div
        ref={this.props.reference}
        className={klasses.join(' ')}
        onClick={this.props.clicked}
      >
        <input
          type={this.props.submit ? 'submit' : 'button'}
          className={classes.ButtonInput}
          value={this.props.children}
        />
      </div>
    )
  }
}
