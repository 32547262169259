import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { track } from '../../mixpanel';
import auth0Client from '../../auth0';

import classes from './Auth.module.css';

import * as authActions from '../../store/actions/auth';

import { setFormValue, isEmailValid } from '../../helpers';

import { signin } from '../../resources/account/account.api';

import Input from '../../components/UI/InputText/Input';
import ItemInner from '../../components/UI/ItemInner/ItemInner';
import Spinner from '../../components/UI/Spinner/Spinner';
import View from '../../components/View/View';
import Button from '../../components/UI/Button/Button';
import StyledButton from '../../components/UI/StyledButton/StyledButton';

import StyledButtonWrap from '../../components/UI/StyledButton/StyledButtonWrap/StyledButtonWrap';

const verificationCodeLength = 4;


class Auth extends Component {
  state = {
    email: '',
    submitting: false,
  };

  setEmail = setFormValue('email').bind(this);
  setCode = setFormValue('code').bind(this);

  async componentDidMount() {
    track('Auth Opened');

    const loggedInThroughCallback = await auth0Client.handleCallback();

    if (loggedInThroughCallback) {
      await this.props.setFirebaseCustomToken(this.props.email);
    }
  }

  onEmailLoginStart = () => {
    const { email } = this.state;
    this.setState({ submitting: true });

    return signin({ email })
      .then(() => {
        this.setState({ errorMessage: '', submitting: false });
        this.props.onEmailLoginStart(email);
      })
      .catch(error => {
        this.setState({ submitting: false });
        if (error.data) {
          const errors = error.data.errors;
          const errorMessage = errors
            .map(err => Object.values(err)[0])
            .join('. ');
          this.setState({ errorMessage });
        } else {
          this.setState({
            errorMessage:
              'Something went wrong. Please try again or contact support.'
          });
        }
      });
  };

  verifyCode = () => {
    const { code } = this.state;
    if (!code) {
      this.setState({ errorMessage: 'Code is required' });

      return;
    } else if (code && String(code).length !== verificationCodeLength) {
      this.setState({ errorMessage: 'Wrong email or verification code.' });

      return;
    } else {
      this.setState({ errorMessage: '' });
    }

    return this.props.verifyCode(this.props.email, this.state.code);
  }

  tryAgain = () => {
    this.setState({ errorMessage: '', code: '', email: '' });
    this.props.tryAgain();
  }

  render() {
    const errorMessage = this.state.errorMessage || this.props._error;

    const pendingAuthMessage = (
      <React.Fragment>
        <p className={classes.Check}>Check your email</p>
        <p className={classes.PendingAuth}>
          We’ve sent a login code to:
          <br />
          <span style={{ color: 'black' }}>{this.props.email}</span>
        </p>
        <p className={classes.PendingAuth}>
          Please check your e-mail and enter the code below.
        </p>
        <div className={classes.EmailInput}>
          <ItemInner label="Code">
            <Input
              inputType="number"
              wide="full"
              placeholder="Enter code..."
              value={this.state.code}
              changed={this.setCode}
            />
          </ItemInner>
        </div>
        {errorMessage && (
          <p className={classes.Error}>{errorMessage}</p>
        )}
        <p>&nbsp;</p>
        <div className={classes.LoginButton}>
          <Button
            clicked={this.verifyCode}
            btnType={'Red'}
          >
            Login
          </Button>
        </div>
        <p>&nbsp;</p>
        <p className={cx(classes.PendingAuth, classes.Resend)}>
          Didn't get the email? <div className={classes.TryAgain} onClick={this.tryAgain}>Try Again</div>
        </p>
      </React.Fragment>
    );

    const authForm = this.props.pendingAuth ? (
      pendingAuthMessage
    ) : (
      <React.Fragment>
        <p className={classes.Hello}>
          Welcome to your Starting <br />
          Strength Gyms Logbook
        </p>
        <div className={classes.EmailInput}>
          <ItemInner label="Email address">
            <Input
              inputType="email"
              wide="full"
              placeholder="Enter email address..."
              value={this.state.email}
              changed={this.setEmail}
            />
          </ItemInner>
        </div>
        {errorMessage && (
          <p className={classes.Error}>{errorMessage}</p>
        )}
        <div className={classes.LoginButton}>
          <StyledButton
            clicked={this.onEmailLoginStart}
            btnType={'Red'}
            disabled={!isEmailValid(this.state.email) || this.state.submitting}
          >
            <StyledButtonWrap caption={'Login'} />
          </StyledButton>
        </div>
      </React.Fragment>
    );

    return (
      <View>
        <div className={classes.Auth}>
          {this.props._loading ? <Spinner /> : authForm}
        </div>
      </View>
    );
  }
}

const mapStateToProps = ({ auth: { _loading, pendingAuth, email, _error } }) => {
  return {
    _error,
    _loading,
    pendingAuth,
    email
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onEmailLoginStart: email => dispatch(authActions.emailLogin(email)),
    verifyCode: (email, code) => dispatch(authActions.verifyCode(email, code)),
    setFirebaseCustomToken: (email) => dispatch(authActions.setFirebaseCustomToken(email)),
    tryAgain: () => dispatch(authActions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
