import * as types from './_types';
import { clearLocalData, fetchFromFirebase } from './user';
import { clearLogbookData } from './logbook';
import { LOCAL_STORAGE_KEY } from '../../config';
import { auth as firebaseAuth } from '../../firebase';
import auth0Client from '../../auth0';
import { identify } from '../../mixpanel';
import { getFirebaseToken } from '../../resources/account/account.api';
import * as Sentry from "@sentry/react";

export const loginStart = () => {
  return {
    type: types.AUTH_LOGIN_START
  }
};

export const loginPending = (email) => {
  return {
    type: types.AUTH_LOGIN_PENDING,
    email,
  }
};

export const loginFail = (error) => {
  return {
    type: types.AUTH_LOGIN_FAIL,
    error
  }
};

export const loginSuccess = (userId, token) => {
  return {
    type: types.AUTH_LOGIN_SUCCESS,
    userId,
    token
  }
};

export const logout = () => {
  return dispatch => {
    firebaseAuth.signOut().then(() => {
      dispatch({ type: types.AUTH_LOGOUT });
      dispatch(clearLocalData());
      dispatch(clearLogbookData());
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      Sentry.setUser(null);
    }).catch(err => {
      console.error(err);
      dispatch(loginFail(err.message));
    })
  }
};

export const setFirebaseCustomToken = (email) => {
  return async dispatch => {
    dispatch(loginStart());

    const idToken = auth0Client.getIdToken();

    const { firebaseToken } = await getFirebaseToken(idToken);

    await signInWithCustomToken(firebaseToken, email)(dispatch);
  }
};

export const signInWithCustomToken = (firebaseCustomToken, email) => {
  return async (dispatch) => {
    try {
      await firebaseAuth.signInWithCustomToken(firebaseCustomToken);

      const user = firebaseAuth.currentUser;

      await user.updateEmail(email);
      identify(user.uid);
      dispatch(fetchFromFirebase());
    } catch (err) {
      console.error(err);
      console.log(firebaseCustomToken)
      dispatch(loginFail(err.message));
    }
  }
};

export const emailLogin = (email) => {
  return dispatch => {
    dispatch(loginStart());

    auth0Client.sendEmailWithCode(email)
      .then(() => {
        dispatch(loginPending(email));
      })
      .catch(err => {
        console.log(err);
        dispatch(loginFail(err))
      });
  }
}

export const verifyCode = (email, code) => {
  return dispatch => {
    dispatch(loginStart());

    auth0Client.verifyCode(email, code)
      .then(() => {
        dispatch(loginPending(email));
      })
      .catch(err => {
        dispatch(loginFail(err.description))
      });
  }
}
