import React, { Component } from 'react';
import { connect } from 'react-redux';
import debug from 'debug';

import classes from './UserProfile.module.css';

import { isEmpty, getURLSearchParam } from '../../shared/utility';

import * as userActions from '../../store/actions/user';

import NumberInput from '../../components/inputs/number';
import SelectBox from '../../components/UI/Selectbox/Selectbox';
import View from '../../components/View/View';
import Spinner from '../../components/UI/Spinner/Spinner';
import { track } from '../../mixpanel';
import ItemInner from '../../components/UI/ItemInner/ItemInner';
import Footer from '../../components/View/Footer/Footer';
import StyledButton from '../../components/UI/StyledButton/StyledButton';
import Navbar from '../../components/View/Navbar/Navbar';
import NavbarTitle from '../../components/View/Navbar/NavbarTitle/NavbarTitle';
import NavbarLeft from '../../components/View/Navbar/NavbarLeft/NavbarLeft';
import NavbarRight from '../../components/View/Navbar/NavbarRight/NavbarRight';
import DrawerToggler from '../../components/View/SideDrawer/DrawerToggler/DrawerToggler';
import Page from '../../components/View/Page/Page';
import PageContent from '../../components/View/PageContent/PageContent';
import StyledButtonWrap from '../../components/UI/StyledButton/StyledButtonWrap/StyledButtonWrap';

const log = debug('Logbook:UserProfile');

class UserProfile extends Component {
  state = {
    isSetupView: false,
    showSideDrawer: false,
    showSubmit: true
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerTogglerHandler = () => {
    this.setState(prevState => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  componentDidMount() {
    track('Profile Opened');
  }

  componentWillMount() {
    const isSetup =
      getURLSearchParam(this.props.location.search, 'setup') || false;
    log(`isSetup=%s`, isSetup);

    const state = {
      gender: this.props.gender,
      bodyWeight: isEmpty(this.props.bodyWeight) ? '' : this.props.bodyWeight,
      heightFt: isEmpty(this.props.heightFt) ? '' : this.props.heightFt,
      heightIn: isEmpty(this.props.heightIn) ? '' : this.props.heightIn,
      waistSize: isEmpty(this.props.waistSize) ? '' : this.props.waistSize,
      hipSize: isEmpty(this.props.hipSize) ? '' : this.props.hipSize,
      chestSize: isEmpty(this.props.chestSize) ? '' : this.props.chestSize
    };
    if (isSetup && isSetup === '1') {
      state.isSetupView = true;
    }

    this.setState(state);
  }

  updatedHandler = (field, value) => {
    log(`updatedHandler %s = %s`, field, value);
    this.setState({
      [field]: value
    });
  };

  isFormValid = () => {
    if (
      !isEmpty(this.state.gender) &&
      this.state.bodyWeight &&
      this.state.heightFt &&
      this.state.waistSize &&
      this.state.hipSize &&
      this.state.chestSize
    ) {
      if (this.state.bodyWeight > 9999 || this.state.bodyWeight < 50) {
        return false;
      }
      if (this.state.heightFt > 9 || this.state.heightFt < 1) {
        return false;
      }
      if (this.state.heightIn > 12 || this.state.heightIn < 0) {
        return false;
      }
      if (this.state.waistSize > 99 || this.state.waistSize < 10) {
        return false;
      }
      if (this.state.hipSize > 99 || this.state.hipSize < 10) {
        return false;
      }
      if (this.state.chestSize > 99 || this.state.chestSize < 10) {
        return false;
      }
      return true;
    }
    return false;
  };

  saveHandler = () => {
    const redirect = getURLSearchParam(this.props.location.search, 'redirect');

    const profile = {
      gender: this.state.gender,
      bodyWeight: +this.state.bodyWeight,
      heightFt: +this.state.heightFt,
      heightIn: +this.state.heightIn,
      waistSize: +this.state.waistSize,
      hipSize: +this.state.hipSize,
      chestSize: +this.state.chestSize
    };

    log(`Save %o and redirect to %s`, profile, redirect);
    this.props.onSave(profile, redirect);
  };

  formSubmitHandler = event => {
    event.preventDefault();
    this.saveHandler();
  };

  inputFocusedHandler = () => {
    this.setState({ showSubmit: true });
  };

  inputBluredHandler = () => {
    this.setState({ showSubmit: true });
  };

  render() {
    if (this.props.loading) {
      return <Spinner />;
    }

    const submitDisabled = !this.isFormValid();

    const genders = { m: 'Male', f: 'Female' };
    const gendersList = Object.keys(genders).map(g => {
      return { id: g, title: genders[g] };
    });

    let form = (
      <form onSubmit={this.formSubmitHandler} action='/' autoComplete='off'>
        <input type='hidden' autoComplete='false' />
        <div className={classes.FlexContainerCols}>
          <div className={classes.FlexItem}>
            <ItemInner label='Sex'>
              <SelectBox
                options={gendersList}
                value={this.state.gender}
                title={genders[this.state.gender]}
                changed={({ target: { value } }) =>
                  this.updatedHandler('gender', value, false)
                }
              />
            </ItemInner>
          </div>
          <div className={classes.FlexItem}>
            <ItemInner label='Weight'>
              <NumberInput
                inputClassName={classes.InputDefault}
                className={classes.FlexContainer}
                suffix='lbs'
                type="text"
                inputMode="decimal"
                min={1}
                max={9999}
                value={this.state.bodyWeight}
                onChange={(value) =>
                  this.updatedHandler('bodyWeight', value)
                }
                onBlur={this.inputBluredHandler}
              />
            </ItemInner>
          </div>
          <div className={classes.FlexContainerRow}>
            <div className={classes.FlexItem}>
              <ItemInner label='Height'>
                <NumberInput
                  inputClassName={classes.InputDefault}
                  className={classes.FlexContainer}
                  suffix='ft'
                  min={1}
                  max={99}
                  type="text"
                  inputMode="decimal"
                  value={this.state.heightFt}
                  onChange={(value) =>
                    this.updatedHandler('heightFt', value)
                  }
                  onBlur={this.inputBluredHandler}
                />
              </ItemInner>
            </div>
            <div className={classes.FlexItem}>
              <ItemInner label={'Height'} hiddenLabel>
                <NumberInput
                  inputClassName={classes.InputDefault}
                  className={classes.FlexContainer}
                  suffix='in'
                  min={0}
                  max={99}
                  type="text"
                  inputMode="decimal"
                  value={this.state.heightIn}
                  onChange={(value) =>
                    this.updatedHandler('heightIn', value)
                  }
                  onBlur={this.inputBluredHandler}
                />
              </ItemInner>
            </div>
          </div>
          <div className={classes.FlexItem}>
            <ItemInner label='Waist' suffix='(Measure around the navel)'>
              <NumberInput
                inputClassName={classes.InputDefault}
                className={classes.FlexContainer}
                suffix='in'
                min={1}
                max={99}
                type="text"
                inputMode="decimal"
                value={this.state.waistSize}
                onChange={(value) =>
                  this.updatedHandler('waistSize', value)
                }
                onBlur={this.inputBluredHandler}
              />
            </ItemInner>
          </div>
          <div className={classes.FlexItem}>
            <ItemInner label='Hips'>
              <NumberInput
                inputClassName={classes.InputDefault}
                className={classes.FlexContainer}
                suffix='in'
                min={1}
                max={99}
                type="text"
                inputMode="decimal"
                value={this.state.hipSize}
                onChange={(value) =>
                  this.updatedHandler('hipSize', value)
                }
                onBlur={this.inputBluredHandler}
              />
            </ItemInner>
          </div>
          <div className={classes.FlexItem}>
            <ItemInner label='Chest'>
              <NumberInput
                inputClassName={classes.InputDefault}
                className={classes.FlexContainer}
                suffix='in'
                min={1}
                max={99}
                type="text"
                inputMode="decimal"
                value={this.state.chestSize}
                onChange={(value) =>
                  this.updatedHandler('chestSize', value)
                }
                onBlur={this.inputBluredHandler}
              />
            </ItemInner>
          </div>
        </div>
        <input type='submit' style={{ display: 'none' }} />
      </form>
    );

    let view;
    if (this.state.isSetupView) {
      // ONBOARDING (first time in)
      view = (
        <View>
          <Page>
            <PageContent withFooter={this.state.showSubmit} whiteBg>
              <div className={classes.OuterWrap}>
                <div className={classes.UserProfile}>
                  <div className={classes.Title}>Tell Us About Yourself.</div>
                  <p className={classes.SubTitle}>
                    Track your body weight and waist size as you make progress
                  </p>
                  {form}
                </div>
              </div>
            </PageContent>
            {this.state.showSubmit && (
              <Footer>
                <StyledButton
                  clicked={this.saveHandler}
                  disabled={submitDisabled}
                >
                  <StyledButtonWrap caption='Continue' />
                </StyledButton>
              </Footer>
            )}
          </Page>
        </View>
      );
    } else {
      view = (
        <View
          showSideDrawer={this.state.showSideDrawer}
          sideDrawerClosedHandler={this.sideDrawerClosedHandler}
          sideDrawer
        >
          <Navbar>
            <NavbarLeft>
              <DrawerToggler clicked={this.sideDrawerTogglerHandler} />
            </NavbarLeft>
            <NavbarTitle title='Personal Information' />
            <NavbarRight empty />
          </Navbar>
          <Page>
            <PageContent withNavbar withFooter={this.state.showSubmit} whiteBg>
              <div className={classes.OuterWrap}>
                <div className={classes.UserProfile}>
                  <div className={classes.Title}>Update your measurements.</div>
                  <p className={classes.SubTitle}>
                    Enter your most current measurements to track your progress over time
                  </p>
                  {form}
                </div>
              </div>
            </PageContent>
            {this.state.showSubmit && (
              <Footer>
                <StyledButton
                  clicked={this.saveHandler}
                  disabled={submitDisabled}
                >
                  <StyledButtonWrap caption='Update' />
                </StyledButton>
              </Footer>
            )}
          </Page>
        </View>
      );
    }

    return view;
  }
}

const mapStateToProps = reduxState => {
  return {
    loading: reduxState.user._loading,

    gender: reduxState.user.gender,
    firstName: reduxState.user.firstName,
    bodyWeight: reduxState.user.bodyWeight,
    heightFt: reduxState.user.heightFt,
    heightIn: reduxState.user.heightIn,
    waistSize: reduxState.user.waistSize,
    hipSize: reduxState.user.hipSize,
    chestSize: reduxState.user.chestSize,
    workouts: reduxState.user.workouts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSave: (newData, redirect) =>
      dispatch(userActions.updateProfile(newData, redirect))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);
