import * as types from "../actions/_types";
import { updateObject } from "../../shared/utility";

export const initialState = {
  _loading: false,
  _error: null,

  firstName: null,
  lastName: null,
  email: null,
  gender: null,
  bodyWeight: null,
  heightFt: null,
  heightIn: null,
  waistSize: null,
  hipSize: null,
  chestSize: null,
  profileHistory: [],
  isConnected: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.USER_UPDATE_START:
      return updateObject(state, { _loading: true });
    case types.USER_UPDATE_SUCCESS:
      return _updateProfileSuccess(state, action);
    case types.USER_UPDATE_HISTORY:
      return {
        ...state,
        profileHistory: action.profileHistory,
      };
    case types.USER_CLEAR_LOCAL_DATA:
      return updateObject(state, {
        ...initialState
      });
    case types.USER_SET_CONNECTION_STATUS:
      return updateObject(state, { isConnected: action.isConnected });
    default:
      return state;
  }
};

//
// PRIVATE
//
const _updateProfileSuccess = (state, action) => {
  return updateObject(state, {
    _loading: false,
    ...action.profile
  });
};
